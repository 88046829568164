@media screen and (max-width: 820px)  {
    #main-nav {
        bottom: $ypadding-small * 1.5;
        padding-left: $xpadding-small;
        padding-right: $xpadding-small;
        ul {
            width: 100%;
        }
    }
    .reel-back-button {
        bottom: $ypadding * 1.5;
    }

    #name-at-the-top {
        top: $ypadding-small;
        font-size: 1.1em;
    }

    .subpage {
        // padding-top: 6em;
        padding-left: $xpadding-small;
        padding-right: $xpadding-small;
    }

    .section-title-main {
        font-size: 0.8em;
        letter-spacing: 0.15em;
    }

    .subpage-text-large {
        font-size: 1.35rem;
        // max-width: 700px;
    }

    .large-font {
        font-size: 1.15em;
    }
    
    #about-section  {
        .skills-list-container {
            margin-top: 2em;
            margin-bottom: 2em;
        }

        .two-part-container {
            .two-part:first-of-type{
                margin-right: 0em;
            }
            .two-part img {
                max-width: 300px;
                border-radius: 10px;
                margin-top: 1em;
            }
        }
    }

}