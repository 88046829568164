.img-gallery-container {
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-around;
    align-items: flex-start;

    .img-item-container {
        display: flex;
        box-sizing: border-box;
        padding: 1em;
        img, video {
            max-width: 100%;
        }
    }
    .img-item-container:first-child {
        margin-top:0;
    }
    
}

.flex-triple-stack {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
}

// alignment
.content-centered {
    align-items: center;
    justify-content: center;
}
.content-full-width-margin {
    width: 100%;
}
.content-interlocking {
    margin-top: -200px;
}
.content-height-cap {
    max-height: 90vh;
}