$xpadding: 60px;
$ypadding: 20px;

$xpadding-small: 30px;
$ypadding-small: 20px;

$primary-color: #ffffff;

$column-gutter: 24px;


@font-face {
    font-family: 'InterTight';
    src: url('../../../public/fonts/InterTight-Regular.ttf');
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('../../../public/fonts/IBMPlexMono-Regular.ttf');
}


@import "./_flexbox_img_layout.scss";
// @import "_case_studies.scss";

body, html {
    width: 100%;
    height: 100%;
    // min-width: fit-content;
}

body {
    font-family: 'InterTight', sans-serif;
    position: relative;
    background-color: #000;
    color: $primary-color;
    margin:0;
}

a {
    text-decoration: none;
    color: $primary-color;
}

#name-at-the-top {
    position: fixed;
    top: $ypadding;
    left: 50%;
    transform: translateX(-50%);    
}
.home-page-font {
    font-size:1.25em;
}
.bottom-layer {
    z-index: 1;
}
.mid-layer {
    z-index: 2;
}

.top-layer {
    z-index: 3;
}

.large-font {
    font-size: 1.5em;
    color: $primary-color;
}

.dimmed {
    opacity: 0.4;
}

#main-nav {
    position: fixed;
    bottom: $ypadding * 2;
    left: 50%;
    transform: translateX(-50%);
    box-sizing: border-box;
    width: 512px;
    
    ul {
        list-style-type: none;
        width: 512px;
        padding: 0;
        margin:0;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
    }
}
.nav-active {
    text-decoration: underline;
}

.reel-back-button {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: $ypadding * 2;
    // margin-left: 0.15em;

    &::before {
        content: url('../../../public/icons/chevron-left.svg');
        display:block;
        width: 0.65em;
        height: 0.65em;
        margin-top: 0.1em;
        margin-right: 0.2em;
        float:left;
    }
}


#herovideo {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: fixed;
    left:0;
    top:0;
    z-index:0;
}

.flex-center {
    width:100%;
    height: 100vh;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
}

#social-media-links {
    margin-top: 2rem;

    a {
        margin-right: 1em;
    }
}

#reel {
    max-width: 90%;
    height:auto;
    max-height: 70vh;
}
#portrait {
    width: 500px;
    height: 400px;
    object-fit: cover;
}
#leftsection, #rightsection {
    float: left;
}
#leftsection {
    margin-right: 5em;
}

.two-columns p {
    float: left;
    width: 50%;
    box-sizing: border-box;
    margin-top:0;
}
.two-columns p:first-of-type {
    text-align: right;
    padding-right: $column-gutter * 0.5;
}
.two-columns p:last-of-type {
    padding-left: $column-gutter * 0.5;
}

main {
    max-width: 1920px;
    margin: 0 auto;
}



.subpage {
    position: absolute;
    top: 0;
    left: 0;
    right:0;
    bottom:0;
    padding: 6em $xpadding; 
    display: block;
    overflow-y: scroll;
    z-index: 1;

    mask-image: linear-gradient(180deg, rgba(0,0,0,0) 7%, rgba(0,0,0,1) 13%, rgba(0,0,0,1) 87%, rgba(0,0,0,0) 93%); 
    -webkit-mask-image: linear-gradient(180deg, rgba(0,0,0,0) 7%, rgba(0,0,0,1) 13%, rgba(0,0,0,1) 87%, rgba(0,0,0,0) 93%); 
    
}

.black-overlay {
    position: fixed;
    pointer-events: none;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: rgba(05,0,0,0.7);
    
}
.technical-font {
    
    font-family: 'IBM Plex Mono';
}
.technical-list {
    list-style-type: none;
    padding: 0 0 0 12px;
    li {
        font-size: 0.75em;
    }
}
.subpage-text-medium {
    font-size: 1.35rem;
    max-width: 700px;
}
.subpage-text-large {
    font-size: 1.875rem;
    max-width: 700px;
    color:white;
}
.subpage-text-body {
    max-width: 700px;
    letter-spacing: 0.3px;
}
.section-title-main {
    text-transform: uppercase;
    font-size: 1.05em;
    letter-spacing: 0.15em;
    &:before {
        content: "[";
    }
    &:after {
        content: "]";
    }
}
.section-title-minor {
    text-transform: uppercase;
    font-family: 'IBM Plex Mono';
    font-size: 0.65em;
    letter-spacing: 0.1rem;;
    &:before {
        content: "[";
    }
    &:after {
        content: "]";
    }
}

#about-section {
    // font-size: 1.15em;
    a {
        text-decoration: underline;
    }
    #casestudies-link {
        margin-left: 1em;
    }
    div {
        float: left;
    }

    .two-part-container {
        .two-part:first-of-type{
            margin-right: 2em;
        }
        .two-part img {
            max-width: 300px;
            border-radius: 10px;
            margin-top: 1em;
        }
    }

    .skills-list-container {
        margin-top: 4em;
        margin-bottom: 4em;
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: start;
        align-items: start;
    }
}

.skills-list {
    width: 13em;
}




@import "_screens_medium.scss";
@import "_screens_small.scss";